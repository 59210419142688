<template>
  <div class="assessment">
    <vue-headful title="Verify Roster - HMD Provider Roster Update Tool" />
    <section class="hero is-primary is-bold">
      <div class="hero-head">
        <div class="container">
          <LoggedInNav />
        </div>
      </div>
      <div class="hero-body">
        <div class="container has-text-centered">
          <img
            src="@/assets/HMD-Logo-2019-white.svg"
            alt="HMD Logo"
            width="275"
          />
          <br /><br />
          <h2 v-if="officeResults" class="subtitle is-5 has-text-centered">
            {{ officeResults[0]["Location Name"] }} <br />
            {{ officeResults[0]["Line Number1"] }}
            {{ officeResults[0]["Line Number2"] }} <br />
            {{ officeResults[0]["City"] }} {{ officeResults[0]["State"] }}
            {{ officeResults[0]["Zip Code"] }}<br />
          </h2>
          <br />
        </div>
      </div>
    </section>

    <section class="container form-wrapper">
      <div class="columns is-multiline">
        <div class="column active is-8 is-offset-2 is-shadow">
          <h1 class="title is-4 has-text-centered">
            HMD Provider Roster Update Tool
          </h1>
          <hr />
          <div class="title-wrapper">
            <b-message
              v-if="hasCompletedAlready"
              type="is-success"
              title="Roster Update Completed"
              has-icon
            >
              Your office has already completed their Roster Update, you do not
              need to do it again.
            </b-message>
            <p class="subtitle is-5">
              Based on our records, the list below reflects participating
              providers within your practice. Please confirm which providers are
              still participating by placing a check mark in the box next to
              their name.
              <br />
              <br />
              If a provider is no longer participating, please leave the box
              next to their name blank.
              <br />
              <br />
              Once complete, fill out your name and phone number, and then click
              the "Submit and Confirm Active Providers" button.
              <br />
              <br />
              To credential a new provider, please contact
              <a href="mailto:credentialing@hmidental.com">
                credentialing@hmidental.com
              </a>
              for assistance.
            </p>
          </div>
          <div class="columns">
            <div v-if="officeResults" class="column is-10 is-offset-1">
              <br />
              <h1 class="title is-4 text-blue">
                <i class="fas fa-clipboard-list-check mr-4"></i>Current
                Providers
              </h1>
              <b-table
                checkable
                searchable
                paginated
                :per-page="10"
                :data="officeResults"
                :columns="columns"
                :checked-rows.sync="checkedRows"
                :checkbox-position="checkboxPosition"
              >
                <template #bottom-left>
                  <b>Selected</b>:
                  {{ checkedRows.length }}
                </template>
              </b-table>
              <hr />
              <b-field grouped>
                <b-field
                  label="Completed By"
                  message="Name of person filling this form on the Office's behalf"
                  expanded
                >
                  <b-input
                    v-model="completedBy.full_name"
                    type="text"
                  ></b-input>
                </b-field>
                <b-field
                  label="Office Phone Number"
                  expanded
                  message="Ex. 123-456-7890"
                >
                  <b-input v-model="completedBy.phone" type="tel"></b-input>
                </b-field>
              </b-field>
              <br />
              <div class="buttons">
                <b-button
                  type="is-primary is-light"
                  size="is-medium"
                  @click="finalSubmit"
                  :disabled="
                    !checkedRows.length ||
                    !completedBy.full_name ||
                    !completedBy.phone
                  "
                  :class="{ 'is-loading': loading }"
                  expanded
                >
                  Submit and Confirm Active Providers
                </b-button>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoggedInNav from "@/components/LoggedInNav";
import { mapGetters } from "vuex";
import { db } from "@/main";
export default {
  components: {
    LoggedInNav,
  },
  data() {
    return {
      hasCompletedAlready: false,
      checkboxPosition: "left",
      checkedRows: [],
      loading: false,
      completedBy: {
        full_name: undefined,
        phone: undefined,
      },
      columns: [
        {
          field: "Last Name",
          label: "Last Name",
        },
        {
          field: "First Name",
          label: "First Name",
        },
        {
          field: "NPI",
          label: "NPI 1",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["officeMeta", "officeResults"]),
  },
  mounted() {
    if (!this.officeResults) this.$router.replace("/login");
    this.checkIfSubmittedAlready();
  },
  methods: {
    checkIfSubmittedAlready() {
      db.collection("confirmed_providers")
        .where("office_tin", "==", this.officeMeta.tin)
        .where("office_npi", "==", this.officeMeta.npi)
        .where("office_zip", "==", this.officeMeta.zip)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.hasCompletedAlready = false;
          } else {
            this.hasCompletedAlready = true;
          }
        });
    },
    filterProviders(providers) {
      return providers.map((provider) => {
        return {
          "First Name": provider["First Name"],
          "Last Name": provider["Last Name"],
          NPI: provider.NPI,
        };
      });
    },
    finalSubmit() {
      this.loading = true;
      let count = 0;
      this.checkedRows.forEach((provider) => {
        db.collection("confirmed_providers")
          .add({
            dentist_first_name: provider["First Name"],
            dentist_last_name: provider["Last Name"],
            dentist_npi: provider.NPI,
            office_name: `${provider["Location Name"]} - ${provider["Line Number1"]} ${provider["Line Number2"]}`,
            office_npi: this.officeMeta.npi,
            office_tin: this.officeMeta.tin,
            office_zip: this.officeMeta.zip,
            submitted_by: this.completedBy.full_name,
            submitted_by_phone: this.completedBy.phone,
            submitted_on: new Date().toString(),
          })
          .then(() => {
            count++;
            if (count === this.checkedRows.length) {
              this.success();
              this.hasCompletedAlready = true;
            }
          });
      });
    },
    success() {
      window.scrollTo(0, 0);
      this.loading = false;
      this.$buefy.toast.open({
        duration: 15000,
        message: `Your office roster has been successfully submitted.`,
        type: "is-success",
        pauseOnHover: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.assessment {
  background: rgb(247, 249, 252);
  background: radial-gradient(
    circle,
    rgba(247, 249, 252, 1) 0%,
    #c5cee0 100%
  ) !important;
  min-height: 100vh;
  padding-bottom: 10rem;
}

.active {
  min-height: 400px;
  border-radius: 15px;
  margin-top: -2rem;
  padding: 1.5rem;
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  @media only screen and (max-width: 768px) {
    margin-top: -2rem;
    margin-left: 01.5rem;
    margin-right: 01.5rem;
  }
}
.title-wrapper {
  padding: 1rem;
  margin-bottom: 1rem;
}
.text-blue {
  color: #0a6bac;
}
</style>
